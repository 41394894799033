<template>
    <div>
        <!-- 富文本编辑框 -->
        <div :id="id_index" class="Wangeditor"></div>
        <el-button type="text" @click="()=>this.see = true">查看</el-button>

        <el-dialog title="预览" :visible.sync="see" :width="this.$props.seewWidth">
            <div class="editor-content-see" v-html="value">

            </div>
        </el-dialog>
    </div>
</template>

<script>
import E from "wangeditor"
import axios from "axios";

export default {
    name: 'Wangeditor',
    props: {
        idindex: [String, Number],
        value: [String],
        seewWidth: [String],
    },
    data() {
        return {
            see: false,
            id_index: 'Wangeditor',
        }
    },
    // 创建
    created() {
        this.id_index = 'Wangeditor_' + this.$props.idindex      // 设置id
    },
    // 安装
    mounted() {
        this.init()     // 初始化编辑器
    },
    methods: {
        // 初始化
        init() {
            let thi = this
            let id_index = '#' + this.id_index
            this.editor = new E(id_index)

            this.editor.config.onchange = function (html) {
                // 第二步，监控变化，同步更新到 textarea
                // console.log('编辑器内容', html)
                thi.$emit('input', html)
            }

            this.editor.config.height = 500     // 设置编辑区域高度为 500px
            this.editor.config.zIndex = 0       // 设置层
            this.editor.config.uploadImgMaxLength = 1   // 一次上传图张数

            // 弹窗信息
            this.editor.config.customAlert = function (s, t) {
                switch (t) {
                    case 'success':
                        thi.$message.success(s);
                        break
                    case 'info':
                        thi.$message.info(s);
                        break
                    case 'warning':
                        thi.$message.warning(s);
                        break
                    case 'error':
                        thi.$message.error(s);
                        break
                    default:
                        thi.$message(s);
                        break
                }
            }

            // 设置字体和背景颜色
            // editor.config.colors = [
            //     '#000000',
            //     '#eeece0',
            //     '#1c487f',
            //     '#4d80bf'
            // ]

            // 自定义上传图片
            this.editor.config.customUploadImg = function (resultFiles, insertImgFn) {
                // console.log('上传的文件',resultFiles[0])
                thi.getUploadtoken(resultFiles[0], insertImgFn, 'image')
            }

            // 自定义上传视频
            this.editor.config.customUploadVideo = function (resultFiles, insertVideoFn) {
                // console.log('上传的文件',resultFiles[0])
                thi.getUploadtoken(resultFiles[0], insertVideoFn, 'video')
            }

            // 创建
            this.editor.create()
            // this.editor.txt.html(this.$props.value) // 填充内容
            // this.editor.txt.append(this.$props.value) // 填充内容
        },
        // 获取token
        getUploadtoken(file, insert, type) {
            // console.log('上传内容', file)
            // 获取上传token
            let postData = {
                api_name: 'home.upload.gettoken',
                token: this.Tool.get_l_cache('token'),
                name: file.name,
                type: type,
            }
            this.Tool.post_data('oss', postData, (json) => {
                if (json.code === 0) {
                    this.upqiniu(json.data, file, insert)
                } else {
                    this.$message.error(json.message);
                }
            })
        },
        // 上传
        upqiniu(tokeninfo, file, insert) {
            // console.log('文件信息',file)

            // 判断文件大小
            const issize = file.size / 1024 / 1024 < tokeninfo.size
            if (!issize) {
                this.$message.warning('不能超过 ' + tokeninfo.size + 'MB')
                return false
            }

            const formdata = new FormData()
            formdata.append('file', file)
            formdata.append('token', tokeninfo.token)
            formdata.append('key', tokeninfo.kyename)
            const headers = {
                headers: {'Content-Type': 'multipart/form-data'}
            }

            // 获取到凭证之后再将文件上传到七牛云空间
            axios.post(tokeninfo.uploadurl, formdata, headers).then(res => {
                let url = tokeninfo.domain + res.data.key
                if (tokeninfo.imgsize != null) {
                    url += tokeninfo.imgsize.lg
                }
                // console.log('文件地址',url)
                insert(url)
            })
        },
        // 追加内容
        append(txt = '') {
            // this.editor.txt.append(txt)
            this.editor.txt.html(txt) // 填充内容
        }

    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
